// used to consistently wrap a section
import React from "react";
import styled from "styled-components";
import { screen, innerWidth } from "../variables/variables";

const Wrapper = styled.div`
  max-width: ${innerWidth};
  margin: 0 auto;
  padding: 0 25px;
  position: relative;
  z-index: 5;  
  @media ${screen.xsmall} {
    padding: 0 40px;
  }
  @media ${screen.medium} {
    padding: 0;
    width: 100%;
  }
`;

const InnerWrapper = ({ className, children }) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

export default InnerWrapper;
