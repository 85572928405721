// site width container
export const siteWidth = "1920px";
export const innerWidth = "1340px";

// regex for email validation
export const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const namePattern = /^[A-Z0-9?]{2,}$/i;
export const phonePattern = /^\+?[0-9\s]{9,}$/i;

// colours
export const mainWhite = "#FFFFFF";
export const mainBlack = "#3c3c3c";
export const mainBlue = "#009ed0";
export const mainRed = "#FF9999";

export const mainFont = `'Poppins', sans-serif`;

// Media queries for devices
export const screen = {
  xsmall: `(min-width: 650px)`, // → tablet vertical
  small: `(min-width: 960px)`, // → tablet horizontal
  medium: `(min-width: 1400px)`, // → small laptops
  large: `(min-width: 1530px)`, // → large laptop and regular size desktop
  xlarge: `(min-width: 1800px)`, // → large size desktop
  withCursor: `not all and (pointer: coarse)`, // → device with cursor. not touch screen devices
};

export const transHover = "all 0.2s ease-in-out";

// animate on scroll value for framer motion
export const whileInVew = {
  initial: {
    opacity: 0,
    y: 18,
  },
  whileInView: {
    opacity: 1,
    y: 0,
  },
  viewport: { once: true },
};
