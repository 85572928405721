// covers the window with this
// use for modal or any opened overlay element
import React from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";

const Wrapper = styled(motion.div)`
  background: linear-gradient(
    80deg,
    rgba(42, 157, 106, 0.6) 34%,
    rgba(0, 158, 208, 0.6)
  );
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 6;
`;

const BlankOverlay = ({ isActive }) => {
  return (
    <AnimatePresence>
      {isActive && (
        <Wrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: isActive ? 1 : 0 }}
          exit={{ opacity: 0 }}
        ></Wrapper>
      )}
    </AnimatePresence>
  );
};

export default BlankOverlay;
