import React, { useState } from "react";
import styled from "styled-components";
import InnerWrapper from "../inner-wrapper/inner-wrapper";
import Logo from "../../images/svg/irada-logo-W.svg";
import {
  mainWhite,
  mainBlack,
  siteWidth,
  transHover,
  screen,
} from "../variables/variables";
import { Link } from "gatsby";
import Dropdown from "./dropdown";
import BlankOverlay from "../blank-overlay/blank-overlay";
import MenuDots from "./menu-dots";

const Wrapper = styled.div`
  background: linear-gradient(
    80deg,
    rgba(42, 157, 106, 1) 42%,
    rgba(0, 158, 208, 1)
  );
  position: fixed;
  z-index: 10;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  max-width: ${siteWidth};

  .subwrapper {
    position: relative;
    transition: ${transHover};
    height: ${(props) => (props.isTop ? "62px" : "50px")};
    @media ${screen.xsmall} {
      height: ${(props) => (props.isTop ? "70px" : "56px")};
    }
    @media ${screen.small} {
      height: ${(props) => (props.isTop ? "105px" : "64px")};
    }
  }

  .logo {
    display: block;
    width: 90px;
    position: absolute;
    left: 0;
    top: 50%;
    transform-origin: left;
    transform: ${(props) =>
      props.isTop ? "translateY(-50%)" : "translateY(-50%) scale(0.7)"};
    @media ${screen.xsmall} {
      width: 120px;
      transform: ${(props) =>
        props.isTop ? "translateY(-50%)" : "translateY(-50%) scale(0.65)"};
    }
    @media ${screen.small} {
      width: 170px;
    }
  }

  .menu-list {
    position: absolute;
    right: 0;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;

    &__each {
      position: relative;
      display: none;
      @media ${screen.small} {
        display: block;
      }

      .link {
        color: ${mainWhite};
        cursor: pointer;
        font-size: ${(props) => (props.isTop ? "1.25rem" : "1rem")};
        font-weight: 400;
        transition: ${transHover};
        display: flex;
        align-items: center;
        height: ${(props) => (props.isTop ? "105px" : "64px")};
        padding: 0 40px;
        border: 0;
        @media ${screen.withCursor} {
          &:hover {
            background: ${mainBlack};
          }
        }
      }

      &--bordered {
        .link {
          border: 1px ${mainWhite} solid;
          padding: 0 30px;
          border-radius: 10px;
          color: ${mainBlack};
          margin: 0 0 0 40px;
          color: ${mainWhite};
          line-height: 30px;
          height: 50px;
          display: flex;
          align-items: center;
          @media ${screen.withCursor} {
            &:hover {
              background: ${mainBlack};
              border: 1px ${mainBlack} solid;
            }
          }
        }
      }

      &--menu-icon {
        display: block;
        @media ${screen.small} {
          display: none;
        }
      }
    }
  }
`;

const Navbar = ({
  solutionLinksTemplate,
  triggerContactForm,
  isMobileMenu,
  setIsMobileMenu,
  isTop,
}) => {
  const [isSolutionIsClick, setIsSolutionIsClick] = useState(false);
  const [isSolutionDropdown, setIsSolutionDropdown] = useState(false);

  const handleRattleClick = () => {
    // if already open just do a little movement - no main solutions page available
    if (isSolutionDropdown) {
      setIsSolutionIsClick(true);
      setTimeout(() => {
        setIsSolutionIsClick(false);
      }, 600);
    } else {
      // good for touchscreen like ipad/tablet
      setIsSolutionDropdown(true);
    }
  };

  return (
    <>
      <Wrapper isTop={isTop}>
        <InnerWrapper>
          <div className="subwrapper">
            <Link className="logo" to="/" arial-label="Logo as home page">
              <Logo />
            </Link>

            <ul className="menu-list">
              <li className="menu-list__each">
                <Link className="link" aria-label="Home page" to="/">
                  Home
                </Link>
              </li>
              <li
                className="menu-list__each"
                onMouseEnter={() => setIsSolutionDropdown(true)}
                onMouseLeave={() => setIsSolutionDropdown(false)}
              >
                <span
                  className="link"
                  aria-label="Solutions page"
                >
                  Solutions
                </span>
                <Dropdown
                  solutionLinksTemplate={solutionLinksTemplate}
                  isTop={isTop}
                  isSolutionDropdown={isSolutionDropdown}
                  isSolutionIsClick={isSolutionIsClick}
                />
              </li>
              <li className="menu-list__each">
                <Link className="link" aria-label="About page" to="/about/">
                  About
                </Link>
              </li>
              <li className="menu-list__each">
                <Link className="link" aria-label="About page" to="/insights/">
                  Insights
                </Link>
              </li>
              <li className="menu-list__each menu-list__each--bordered">
                <Link
                  className="link"
                  aria-label="Contact page"
                  to="/"
                  onClick={triggerContactForm}
                >
                  Contact
                </Link>
              </li>
              <li className="menu-list__each menu-list__each--menu-icon">
                <MenuDots
                  isMobileMenu={isMobileMenu}
                  setIsMobileMenu={setIsMobileMenu}
                  isTop={isTop}
                />
              </li>
            </ul>
          </div>
        </InnerWrapper>
      </Wrapper>
      <BlankOverlay isActive={isSolutionDropdown} />
    </>
  );
};

export default Navbar;
