import React from "react";
import styled from "styled-components";
import { mainWhite, transHover } from "../variables/variables";

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50px;
  height: 30px;
  transition: ${transHover};
  transform: ${(props) => (props.isTop ? "scale(1)" : "scale(0.82)")};
  transform-origin: right;

  .dot {
    background: ${mainWhite};
    border-radius: 50%;
    display: block;
    width: 6px;
    height: 6px;

    &--one {
      transform: translateX(-8px);
      transform: ${(props) => `translateX(${props.dot1Position})`};
      transition: ${transHover};
      transition-delay: 0.1s;
    }

    &--two {
      transform: translateX(-4px);
      transform: ${(props) => `translateX(${props.dot2Position})`};
      transition: ${transHover};
    }
  }
`;

const MenuDots = ({ isMobileMenu, setIsMobileMenu, isTop }) => {
  const toggleMenu = () => setIsMobileMenu(!isMobileMenu);
  let dot1Position;
  let dot2Position;

  if (isMobileMenu) {
    dot2Position = "6px";
    dot1Position = "12px";
  } else {
    dot1Position = "-12px";
    dot2Position = "-6px";
  }

  return (
    <Wrapper
      dot1Position={dot1Position}
      dot2Position={dot2Position}
      role="button"
      onClick={toggleMenu}
      isTop={isTop}
    >
      <span className="dot dot--one"></span>
      <span className="dot dot--two"></span>
      <span className="dot"></span>
    </Wrapper>
  );
};

export default MenuDots;
