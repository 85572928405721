import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { toSlug } from "../utils/helpers";
import MobileOverlayMenu from "./mobile-overlay-menu";
import Navbar from "./navbar";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const Nav = (props) => {
  const data = useStaticQuery(dataQuery);
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const [isTop, setIsTop] = useState(true);

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y > -700) {
        setIsTop(true);
      } else {
        setIsTop(false);
      }
    },
    [isTop]
  );

  // return array of objects - dropdown menu links for solutions
  const solutionLinksTemplate = Array.isArray(
    data.dropdownSolution.data.intro_box_list
  )
    ? data.dropdownSolution.data.intro_box_list.map((each) => {
        return { label: each.label, slug: toSlug(each.label) };
      })
    : [];

  return (
    <>
      <Navbar
        {...props}
        isMobileMenu={isMobileMenu}
        setIsMobileMenu={setIsMobileMenu}
        solutionLinksTemplate={solutionLinksTemplate}
        isTop={isTop}
      />
      <MobileOverlayMenu
        solutionLinksTemplate={solutionLinksTemplate}
        isTop={isTop}
        isMobileMenu={isMobileMenu}
        setIsMobileMenu={setIsMobileMenu}
        {...props}
      />
    </>
  );
};

export default Nav;

const dataQuery = graphql`
  {
    dropdownSolution: prismicHomePage {
      data {
        intro_box_list {
          label
        }
      }
    }
  }
`;
