import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  siteWidth,
  mainWhite,
  mainFont,
  mainBlack,
  screen,
} from "./variables/variables";
import styled from "styled-components";
import Nav from "../components/nav/nav";
import Footer from "../components/footer/footer";
import ContactForm from "./lightbox/contact-form";

const Wrapper = styled.div`
  background: ${mainWhite};
  color: ${mainBlack};
  font-family: ${mainFont};
  max-width: ${siteWidth};
  margin: 0 auto;

  .heading {
    font-size: 1.4rem;
    font-weight: 700;
    margin: 0 0 16px 0;
    @media ${screen.xsmall} {
      font-size: 2.4rem;
      margin: 0 0 21px 0;
    }

    &--semi-bold {
      font-weight: 600;
    }
  }

  .description {
    p {
      font-size: 0.85rem;
      font-weight: 300;
      margin: 0 0 16px 0;
      @media ${screen.xsmall} {
        font-size: 1rem;
        margin: 0 0 21px 0;
      }
      &:last-child {
        margin: 0;
      }
    }

    strong {
      font-weight: 600;
    }

    h3 {
      font-size: 0.95rem;
      margin: 0 0 16px 0;
      @media ${screen.xsmall} {
        font-size: 1.1rem;
        margin: 0 0 21px 0;
      }
    }

    a {
      color: ${mainWhite};
      @media ${screen.withCursor} {
        &:hover {
          color: ${mainBlack};
        }
      }
    }

    &--banner {
      p {
        font-size: 0.9rem;
        @media ${screen.xsmall} {
          font-size: 1.1rem;
        }
      }
    }
  }
`;

const Layout = ({ children }) => {
  const [isContactForm, setIsContactForm] = useState(false);

  const triggerContactForm = (e) => {
    e.preventDefault();
    setIsContactForm(true);
  };

  return (
    <Wrapper>
      <Nav triggerContactForm={triggerContactForm} />
      <main>{children}</main>
      <Footer triggerContactForm={triggerContactForm} />
      <ContactForm
        isContactForm={isContactForm}
        setIsContactForm={setIsContactForm}
      />
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
