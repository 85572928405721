// rotating logo in the background of a section
import React from "react";
import styled, { keyframes } from "styled-components";
import { screen } from "../variables/variables";
import IconIrada from "../../images/svg/icon-irada-white.svg";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  position: absolute;
  width: 70%;
  top: 5%;
  z-index: 1;
  right: -35%;
  opacity: 0.05;
  pointer-events: none;
  @media ${screen.xsmall} {
    top: 50%;
    transform: translateY(-50%);
  }

  svg {
    animation: ${spin} 175s linear infinite;
  }
`;

const AnimatedLogo = ({ className }) => {
  return (
    <Wrapper className={className} aria-hidden="true">
      <IconIrada />
    </Wrapper>
  );
};

export default AnimatedLogo;
