// used in mobile nav sublink dropdown
import React from "react";
import styled from "styled-components";
import { transHover } from "../variables/variables";

const Wrapper = styled.div`
  position: absolute;
  right: 30px;
  top: 28px;
  .arrow-icon {
    display: block;
    position: relative;
    cursor: pointer;
    background: red;
  }

  .left-bar {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    width: 12px;
    height: 1px;
    display: block;
    transform: rotate(35deg);
    float: right;

    &:after {
      content: "";
      background-color: white;
      width: 12px;
      height: 1px;
      display: block;
      float: right;
      transition: ${transHover};
      z-index: -1;
    }
  }

  .right-bar {
    position: absolute;
    background-color: transparent;
    top: 0px;
    left: 9px;
    width: 12px;
    height: 1px;
    display: block;
    transform: rotate(-35deg);
    float: right;

    &:after {
      content: "";
      background-color: white;
      width: 12px;
      height: 1px;
      display: block;
      float: right;
      transition: ${transHover};
      z-index: -1;
    }
  }

  .open {
    .left-bar:after {
      transform-origin: center center;
      transform: rotate(-35deg);
    }
    .right-bar:after {
      transform-origin: center center;
      transform: rotate(35deg);
    }
  }
`;

const AnimatedArrow = ({ isDropdown }) => {
  return (
    <Wrapper>
      <div className={isDropdown ? "arrow-icon open" : "arrow-icon"}>
        <span className="left-bar"></span>
        <span className="right-bar"></span>
      </div>
    </Wrapper>
  );
};

export default AnimatedArrow;
