import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import {
  mainBlack,
  mainRed,
  mainWhite,
  screen,
  emailPattern,
  phonePattern,
  namePattern,
  mainFont,
} from "../variables/variables";
import { MdClose } from "react-icons/md";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { opacity: 0, y: -100 },
  show: { opacity: 1, y: 0 },
};

const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${mainWhite};

  .inner-container {
    background: ${mainBlack};
    border-radius: 12px;
    padding: 35px 25px;
    position: relative;
    width: calc(100% - 20px);
    min-height: 300px;
    @media ${screen.xsmall} {
      padding: 50px 60px;
      width: 800px;
    }

    .top-txt {
      margin: 0 0 30px 0;

      .subheading {
        font-size: 1rem;
        font-weight: 700;
        max-width: 350px;

        @media ${screen.xsmall} {
          margin: 0 0 21px 0;
          font-size: 1.4rem;
        }
      }
    }

    .flex-row {
      display: flex;
      margin: 0 0 20px 0;
      @media ${screen.xsmall} {
        margin: 0 0 30px 0;
      }

      .col {
        width: 50%;

        &--left {
          margin: 0 11px 0 0;
          @media ${screen.xsmall} {
            margin: 0 20px 0 0;
          }
        }

        &--right {
          margin: 0 0 0 11px;
          @media ${screen.xsmall} {
            margin: 0 0 0 20px;
          }
        }

        &--full {
          width: 100%;
        }
      }
    }

    .footnote {
      font-size: 0.75rem;
      font-weight: 100;
      @media ${screen.xsmall} {
        font-size: 0.8rem;
      }

      &--warning {
        color: ${mainRed};
        font-size: 1.5rem;
        text-align: center;
        margin: 30px 0 0 0;
      }
    }

    .recaptcha {
      padding-top: 1rem;
    }

    .btn {
      max-width: max-content;
      margin: 30px auto 0 auto;
      @media ${screen.xsmall} {
        margin: 45px auto 0 auto;
      }
    }

    .close-btn {
      line-height: 30px;
      background: none;
      border: 0;
      border-radius: none;
      color: ${mainWhite};
      font-size: 1.6rem;
      position: absolute;
      z-index: 4;
      right: 14px;
      top: 14px;
      cursor: pointer;
      padding: 0;
      @media ${screen.xsmall} {
        font-size: 2rem;
        right: 20px;
        top: 20px;
      }
    }
  }

  #overlay-feedback {
    background: rgba(30, 30, 30, 0.7);
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    display: flex;
    text-align: center;
    z-index: 3;

    .txt-wrap {
      margin: auto;

      .subheading {
        margin: 12px 0 0 0;
      }
    }
  }
  .cog-branding {
    display: none !important;
  }
  .cog-abuse.cog-wrapper {
    display: none !important;
  }
  .cog-form__container {
    background-color: transparent !important;
    h1, label, .cog-header__description, .cog-content {
      color: #fff !important;
      font-family: ${mainFont} !important;
      p {
        color: #fff !important;
        font-family: ${mainFont} !important;
      }
    }
    .cog-col.is-error {
      &::before {
        outline: none !important;
        background-color: transparent !important;
      }
      &::after {
        background: none !important;
        outline: none !important;
      }
    }
    label {
      font-size: 0.8rem !important;
      font-weight: 100 !important;
    }
    h1 {
      font-size: 2.4rem !important;
      margin: 0px 0px 21px !important;
    }
    .cog-header__description {
      margin: 0px 0px 21px !important;
      font-size: 1.4rem !important;
      max-width: 350px !important;
      font-weight: 700 !important;
    }
    input, textarea {
      line-height: 1.6 !important;
      background-color: transparent !important;
      border: 0 !important;
      border-bottom: 1px solid #fff !important;
      font-size: 1.4rem !important;
      padding: 9px 0px !important;
      box-shadow: none !important;
      color: #fff !important;
      font-family: ${mainFont};
    }
    .cog-row{
      display: flex !important;
    }
    .cog-page__navigation {
      margin-top: 60px !important;
      justify-content: center !important;
      display: flex !important;
    }
    button {
      font-weight: 600 !important;
      height: 50px !important;
      width: 266px !important;
      background-color: transparent !important;
      font-family: ${mainFont};
      font-size: 1.075rem !important;
      border: 1px solid #fff !important;
      border-radius: 8px !important;
      &:hover {
        background-color: #fff !important;
        color: #3c3c3c !important;
      }
    }
  }
  #example-container{
    position: absolute;
    bottom: 120px;
  }
`;

// requirements for netlify form
function encode(data) {
  return Object.keys(data)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

const ContactForm = ({ isContactForm, setIsContactForm }) => {
  const [userInput, setUserInput] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  

  const handleChange = (e) => {
    userInput[e.target.name] = e.target.value;

    setUserInput({ ...userInput });
  };

  // scroll lock when it's open
  useEffect(() => {
    if (isContactForm) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "visible";
    }
  }, [isContactForm]);

  // close form and reset all states
  const closeContactForm = () => {
    setIsContactForm(false);
    resetState();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isAllInputValid =
      // it shouldn't be a number only
      namePattern.test(userInput.first_name) &&
      // it shouldn't be a number only
      namePattern.test(userInput.last_name) &&
      // it should be an email
      emailPattern.test(userInput.email_address) &&
      // it should be a phone number
      phonePattern.test(userInput.phone_number) &&
      // it shouldn't be a number only
      namePattern.test(userInput.company) &&
      // it shouldn't be a number only
      namePattern.test(userInput.message);


    // based on client requirements - shouldn't be able to submit with only numbers
    if (isAllInputValid) {
      setIsLoading(true);

      // this post request strictly use for netlify form only
      try {
        const result = await fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": event.target.getAttribute("name"),
            ...userInput,
          }),
        });

        if (result.status === 200) {
          setIsSuccess(true);
          setUserInput({});
          setIsLoading(false);

          // automatic close success feedback along with form
          setTimeout(() => {
            setIsSuccess(false);
            closeContactForm();
          }, 3500);
        }

        if (result.status !== 200) {
          resetState();
          alert("Something went wrong, Please call us instead.");
        }
      } catch (e) {
        console.error(e);
        alert("Something went wrong, Please email us instead.");
      }
    } else {
      setIsError(true);
    }
  };

  useEffect(() => {
    const script = window.document?.createElement('script');
    script.src = 'https://www.cognitoforms.com/f/seamless.js';
    script.setAttribute('data-key', 'g-paI3nQPk28XKxxLCLG8g');
    script.setAttribute('data-form', '1');
    document.getElementById('cognito-form')?.appendChild(script);
  });

  useEffect(() => {
    const script = window.document?.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback';
    script.async = true;
    document.body.appendChild(script);
    window.onloadTurnstileCallback = function () {
      turnstile.render('#example-container', {
          sitekey: '0x4AAAAAAAS00GQzetjOCJSn',
          callback: function(token) {
              console.log(`Challenge Success ${token}`);
          },
        });
    };
    const myTimeout = setTimeout(() => {
      const exampleContainer = document.getElementById('example-container');
      if (exampleContainer) {
        exampleContainer.remove();
      }
    }, 8000);
    return () => {
      clearTimeout(myTimeout);
      document.body.removeChild(script);
    };
  });

  const resetState = () => {
    setIsSuccess(false);
    setUserInput({});
    setIsLoading(false);
  };

  return (
    <AnimatePresence>
      {isContactForm && (
        <Wrapper
          variants={container}
          initial="hidden"
          animate={isContactForm ? "show" : "hidden"}
          exit={{ opacity: 0 }}
          aria-label="Overlay contact form"
        >
          <div id='cognito-form' className="inner-container">

            <div id='example-container'></div>
            <button
              className="close-btn"
              aria-label="Close contact form lightbox"
              onClick={closeContactForm}
            >
              <MdClose />
            </button>

            {isSuccess && (
              <div id="overlay-feedback">
                <div className="txt-wrap">
                  <span className="heading">Thank you!</span>
                  <p className="subheading">We'll get back to you shortly!</p>
                </div>
              </div>
            )}
          </div>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

export default ContactForm;
