import slugify from "slugify";

// return string of page url based on title string
export const toSlug = (string, isUnderscore) => {
  if (string) {
    return slugify(string, {
      replacement: !isUnderscore ? "-" : "_", // replace spaces with replacement character, defaults to `-`
      remove: undefined, // remove characters that match regex, defaults to `undefined`
      lower: true, // convert to lower case, defaults to `false`
      strict: true, // strip special characters except replacement, defaults to `false`
    });
  }

  return "";
};
