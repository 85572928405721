import React from "react";
import styled, { keyframes, css } from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import {
  mainWhite,
  mainBlack,
  transHover,
  screen,
} from "../variables/variables";
import { Link } from "gatsby";

// rattle movement - gives user a little warning
const rattle = keyframes`
  0% {
 transform: translateX(-2px);
  }
  25% {
transform: translateY(2px) rotate(2deg); 
  }
  75% {
    -webkit-transform: translateY(-2px);
  }
  100% {
    -webkit-transform: translateY(2px);
  }
`;

// framer motion config for variants
const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.07,
    },
  },
};

const item = {
  hidden: { opacity: 0, x: -14, y: 14 },
  show: { opacity: 1, x: 0, y: 0 },
};

const Wrapper = styled(motion.div).withConfig({
  shouldForwardProp: (prop) => !["isTop", "isSolutionIsClick"].includes(prop),
})`
  background-color: ${mainBlack};
  position: absolute;
  top: ${(props) => (props.isTop ? "105px" : "64px")};
  left: 0;
  border-radius: 0 0 10px 10px;

  .sublink-list {
    padding: 0 30px;

    &__each {
      a {
        color: ${mainWhite};
        white-space: pre;
        font-size: 0.88rem;
        font-weight: 500;
        padding: 21px 10px;
        display: block;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        position: relative;
        text-transform: uppercase;
        animation: ${(props) =>
          props.isSolutionIsClick
            ? css`
                ${rattle} 0.08s 5 alternate
              `
            : `none`};

        &:after {
          content: "";
          background-color: ${mainWhite};
          position: absolute;
          left: -30px;
          width: 4px;
          height: 70%;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0;
          transition: ${transHover};
        }

        @media ${screen.withCursor} {
          &:hover ::after {
            opacity: 1;
          }
        }
      }

      &:last-child {
        a {
          border-bottom: none;
        }
      }

      .active::after {
        opacity: 1;
      }
    }
  }
`;

const Dropdown = ({
  isSolutionDropdown,
  isTop,
  isSolutionIsClick,
  solutionLinksTemplate,
}) => {
  return (
    <AnimatePresence>
      {isSolutionDropdown && (
        <Wrapper
          isTop={isTop}
          isSolutionIsClick={isSolutionIsClick}
          variants={container}
          initial="hidden"
          animate={isSolutionDropdown ? "show" : "hidden"}
          exit={{ opacity: 0 }}
        >
          <ul className="sublink-list">
            {solutionLinksTemplate.map((eachLink) => (
              <motion.li
                variants={item}
                className="sublink-list__each"
                key={eachLink.slug}
              >
                <Link activeClassName="active" to={`/${eachLink.slug}/`}>
                  {eachLink.label}
                </Link>
              </motion.li>
            ))}
          </ul>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

export default Dropdown;
