// json ld data
export const organization = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Irada",
  legalName: "Irada PTY LTD",
  url: "https://www.irada.com.au",
  logo: "https://www.irada.com.au/irada-og.png",
  foundingDate: "2017",
  founders: [
    {
      "@type": "Person",
      name: "Sanjiv Singh",
    },
  ],
  address: {
    "@type": "PostalAddress",
    streetAddress: "Level 4 Suite 13, 150 Albert Road",
    addressLocality: "Level 4 Suite 13, 150 Albert Road, South Melbourne VIC",
    addressRegion: "Australia",
    postalCode: "3205",
    addressCountry: "AU",
  },
  contactPoint: {
    "@type": "ContactPoint",
    contactType: "customer support",
    telephone: "[1300 247 232]",
    email: "info@irada.com.au",
  },
  sameAs: ["https://www.linkedin.com/company/irada-digital/"],
};
