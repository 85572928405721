import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { AnimatePresence, motion } from "framer-motion";
import {
  mainWhite,
  mainBlack,
  transHover,
  screen,
} from "../variables/variables";
import BlankOverlay from "../blank-overlay/blank-overlay";
import AnimatedArrow from "./animated-arrow";

// framer motion config for variants
const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.07,
    },
  },
};

const item = {
  hidden: { opacity: 0, y: 24 },
  show: { opacity: 1, y: 0 },
};

const Wrapper = styled(motion.div).withConfig({
  shouldForwardProp: (prop) => !["isTop"].includes(prop),
})`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  @media ${screen.small} {
    display: none;
  }

  .menu-list {
    background-color: ${mainBlack};
    border-radius: 0 0 11px 10px;
    margin: ${(props) => (props.isTop ? "62px 0 0 0" : "50px 0 0 0")};
    position: relative;
    z-index: 9;
    padding: 0 25px;
    @media ${screen.xsmall} {
      padding: 0 40px;
      margin: ${(props) => (props.isTop ? "70px 0 0 0" : "56px 0 0 0")};
    }

    &__each {
      .link {
        color: ${mainWhite};
        font-size: 0.88rem;
        display: block;
        padding: 20px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      }

      &--with-dropdown {
        position: relative;

        .dropdown-list {
          padding: 0 0 0 25px;

          &__each-link {
            color: ${mainWhite};
            display: block;
            padding: 15px 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.25);
            font-size: 0.8rem;
            position: relative;
          }

          .active {
            &:after {
              content: "";
              background-color: ${mainWhite};
              position: absolute;
              left: -12px;
              width: 1.5px;
              height: 57%;
              top: 50%;
              transform: translateY(-50%);
              transition: ${transHover};
            }
          }
        }
      }

      &:last-child {
        .link {
          border-bottom: none;
        }
      }
    }
  }
`;

const MobileOverlayMenu = ({
  triggerContactForm,
  solutionLinksTemplate,
  isTop,
  isMobileMenu,
  setIsMobileMenu,
}) => {
  const [isDropdown, setIsDropdown] = useState(false);

  const handleDropdownToggle = (e) => {
    e.preventDefault();
    setIsDropdown(!isDropdown);
  };

  // scroll lock when it's open
  useEffect(() => {
    if (isMobileMenu) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "visible";
    }
  }, [isMobileMenu]);

  const closeMobileOverlay = (e) => {
    // close menu if already on the same page
    setIsMobileMenu(false);
  };

  return (
    <AnimatePresence>
      {isMobileMenu && (
        <Wrapper
          initial="hidden"
          variants={container}
          animate={isMobileMenu ? "show" : "hidden"}
          isTop={isTop}
          exit={{ opacity: 0, y: -54 }}
        >
          <ul className="menu-list">
            <motion.li variants={item} className="menu-list__each">
              <Link className="link" to="/" onClick={closeMobileOverlay}>
                HOME
              </Link>
            </motion.li>
            <motion.li
              variants={item}
              className="menu-list__each menu-list__each--with-dropdown"
            >
              <Link className="link" to="/" onClick={handleDropdownToggle}>
                SOLUTIONS
              </Link>

              {isDropdown && (
                <div className="dropdown-list">
                  {solutionLinksTemplate.map((eachLink) => (
                    <Link
                      className="dropdown-list__each-link"
                      activeClassName="active"
                      key={eachLink.slug}
                      to={`/${eachLink.slug}/`}
                      onClick={closeMobileOverlay}
                    >
                      {eachLink.label}
                    </Link>
                  ))}
                </div>
              )}

              <AnimatedArrow isDropdown={isDropdown} />
            </motion.li>
            <motion.li variants={item} className="menu-list__each">
              <Link className="link" to="/about/" onClick={closeMobileOverlay}>
                ABOUT
              </Link>
            </motion.li>
            <motion.li variants={item} className="menu-list__each">
              <Link
                className="link"
                to="/insights/"
                onClick={closeMobileOverlay}
              >
                INSIGHTS
              </Link>
            </motion.li>
            <motion.li variants={item} className="menu-list__each">
              <Link className="link" to="/" onClick={triggerContactForm}>
                CONTACT
              </Link>
            </motion.li>
          </ul>

          <BlankOverlay isActive={true} />
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

export default MobileOverlayMenu;
