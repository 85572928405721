import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import InnerWrapper from "../inner-wrapper/inner-wrapper";
import {
  mainBlack,
  mainWhite,
  mainBlue,
  screen,
  whileInVew,
} from "../variables/variables";
import FooterLogo from "../../images/svg/footer_logo.svg";
import AnimatedLogo from "../animated-logo/animated-logo";
import { Link } from "gatsby";
import Obfuscate from "react-obfuscate";
import { FaLinkedin } from "react-icons/fa";

const Wrapper = styled.footer`
  .top {
    background-color: ${mainBlack};
    color: ${mainWhite};
    padding: 35px 0;
    position: relative;
    overflow: hidden;
    @media ${screen.xsmall} {
      padding: 75px 0;
    }

    .flex-row {
      @media ${screen.xsmall} {
        display: flex;
      }
      
      .jusBetween {
          justify-content: space-between;
      }
        
      .w50 {
          width: 100%;
          @media ${screen.xsmall} {
              width: 80%;
          }
          @media ${screen.small} {
              width: 50%;
          }
      }

      .col {
        .link {
          color: ${mainWhite};
          display: block;
          max-width: 220px;
          font-size: 0.8rem;
          font-weight: 300;
          line-height: 1.5;
          text-align: center;
          @media ${screen.xsmall} {
            text-align: left;
            font-size: 14px;
            max-width: max-content;
          }
          @media ${screen.withCursor} {
            &:hover {
              color: ${mainBlue};
            }
          }

          &--social-icon {
            /* margin: 0 6px; */
            font-size: 1.7rem;
            @media ${screen.xsmall} {
              /* margin: 0 0 0 11px; */
              font-size: 1.8rem;
            }
          }
        }

        &--one {
          @media ${screen.xsmall} {
              width: 50%;
          }

          .logo {
            display: block;
            margin: 0 auto;
              width: fit-content;
            @media ${screen.xsmall} {
              margin: 0;
                width: fit-content;
            }
            @media ${screen.small} {
              width: 127px;
            }
            @media ${screen.medium} {
              width: 142px;
            }
          }
        }

        &--two {
          display: flex;
          justify-content: center;
          margin: 30px 0 0 0;
          @media ${screen.xsmall} {
            display: block;
            margin: 0;
          }

          .link {
            display: block;
            margin: 0 14px;
            font-weight: 700;
            @media ${screen.xsmall} {
              font-weight: 300;
            }
          }
        }

        &--three {
          margin: 30px 0;
          align-items: center;
          display: flex;
          flex-direction: column;
          @media ${screen.xsmall} {
            margin: 0;
            max-width: 220px;
            display: block;
          }
        }

        &--four {
          @media ${screen.xsmall} {
            width: 16.5%;
          }

          .subheading {
            display: block;
            font-size: 0.88rem;
            font-weight: 400;
            margin: 0 0 15px 0;
            line-height: 1.5;
            white-space: pre;
            text-align: center;
            @media ${screen.xsmall} {
              text-align: left;
            }
          }

          .social-list {
            display: flex;
            justify-content: center;
            @media ${screen.xsmall} {
              justify-content: flex-start;
            }
          }
        }

        &--five {
          @media ${screen.xsmall} {
            width: 17.5%;
          }

          a {
            max-width: max-content;
            width: 78px;
            margin: 15px auto 0 auto;
            display: block;
            @media ${screen.xsmall} {
              width: 70px;
              margin: 0 0 0 auto;
            }
            @media ${screen.small} {
              width: 88px;
            }
            @media ${screen.medium} {
              width: 100px;
            }

            svg {
              width: 100%;
            }
          }
        }
      }
    }

    .logo-bg {
      right: auto;
      width: 50%;
      left: -25%;
    }
  }

  .bot {
    padding: 15px 0;
    @media ${screen.xsmall} {
      padding: 20px 0;
    }

    .flex-row {
      text-align: center;
      @media ${screen.xsmall} {
        display: flex;
        justify-content: space-between;
        text-align: left;
      }

      .stamp {
        display: block;
        font-size: 0.6rem;
        font-weight: 300;
        margin: 5px 0;
        @media ${screen.xsmall} {
          font-size: 0.8rem;
          margin: 0;
        }

        .link {
          color: ${mainBlack};
          @media ${screen.withCursor} {
            &:hover {
              color: ${mainBlue};
            }
          }

          &--underlined {
            text-decoration: underline;
          }
        }
      }
    }
  }
`;

const Footer = ({ triggerContactForm }) => {
  const currentYear = new Date().getFullYear();

  return (
    <Wrapper>
      <div className="top">
        <InnerWrapper>
          <motion.div {...whileInVew} className="flex-row jusBetween">
            <div className="col col--one">
              <Link className="logo" aria-label="Home page link" to="/">
                <FooterLogo />
              </Link>
            </div>
            <div className="flex-row jusBetween w50">
              <div className="col col--two">
                <Link className="link" to="/" aria-label="Home page">
                  Home
                </Link>
                <Link className="link" to="/solutions" aria-label="Home page">
                  Solutions
                </Link>
                <Link className="link" to="/about/" aria-label="About page">
                  About us
                </Link>
                <Link
                  className="link"
                  to="/"
                  aria-label="Contact form"
                  onClick={triggerContactForm}
                >
                  Contact
                </Link>
              </div>
              <div className="col col--three">
                <a className="link" href="tel:1300247232">
                  Call us on 1300 247 232
                </a>
                <Obfuscate
                  className="link"
                  email="info@irada.com.au"
                  obfuscateChildren={false}
                >
                  Email us at info@irada.com.au
                </Obfuscate>
                <a
                  className="link"
                  href="https://goo.gl/maps/5hKMK5fD1SfGxY5VA"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Level 4, Suite 13/150 Albert Rd, South Melbourne VIC 3205
                </a>
              </div>
              <div className="col col--four">
                <span className="subheading">Follow us!</span>
                <div className="social-list">
                  <a
                    className="link link--social-icon"
                    href="https://www.linkedin.com/company/irada-digital/about"
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label="LinkedIn profile"
                  >
                    <FaLinkedin />
                  </a>
                </div>
              </div>
            </div>
          </motion.div>
        </InnerWrapper>

        <AnimatedLogo className="logo-bg" />
      </div>

      <div className="bot">
        <InnerWrapper>
          <motion.div {...whileInVew} className="flex-row">
            <span className="stamp">
              &copy; IRADA {currentYear || "2022"}. ALL RIGHTS RESERVED.{" "}
              <Link className="link" to="/privacy-policy/">
                PRIVACY POLICY
              </Link>
            </span>

            {/* <span className="stamp">
              Designed and built by{" "}
              <a
                className="link link--underlined"
                href="https://www.capital-e.com.au"
                target="_blank"
                rel="noopener"
              >
                CAPITAL-e
              </a>
            </span> */}
          </motion.div>
        </InnerWrapper>
      </div>
    </Wrapper>
  );
};

export default Footer;
